@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100;300;400;500&display=swap);
body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.App {
	text-align: center;
	font-family: "Raleway", sans-serif;
	font-weight: 500;
	background-color: #0d101d;
}

.noselect {
	pointer-events: none;

	-webkit-user-drag: none;
	-khtml-user-drag: none;
	-moz-user-drag: none;
	-o-user-drag: none;

	-webkit-touch-callout: none;

	-webkit-user-select: none;
	-o-user-select: none;
	-ms-user-select: none;
	    user-select: none;
}

.App-logo {
	width: 75%;
	max-width: 1000px;
}
.App-header {
	background-color: #0d101d;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

#contact {
	color: white;
}

@media (min-width: 992px) {
	.col-lg-8 {
		flex: initial;
		align-items: initial;
		justify-content: initial;
		max-width: none;
		max-width: initial;
		flex: initial;
		max-width: initial;
	}
}
.contactSection {
	width: 500px;
	min-width: 25%;
	max-width: 90%;
}
#contactForm > div > div {
	width: 100%;
}

.tab-content {
	display: flex;
	align-items: center;
	justify-content: center;
}
.tab-pane {
	width: 500px;
	min-width: 25%;
	max-width: 90%;
}
.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.fade {
	transition: opacity 0.15s linear;
	transition-property: opacity;
	transition-duration: 1s;
	transition-timing-function: linear;
	transition-delay: 0s;
}
.fadeIn { /* Safari, Chrome and Opera > 12.1 */ /* Firefox < 16 */ /* Internet Explorer */ /* Opera < 12.1 */
	animation: fadein 5s;
}

@keyframes fadein {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

/* Firefox < 16 */

/* Safari, Chrome and Opera > 12.1 */

/* Internet Explorer */

/* Opera < 12.1 */

.floating-label-form-group {
	position: relative;
	margin-bottom: 0;
	padding-bottom: 0.5em;
	border-bottom: 1px solid #eee;
}

.floating-label-form-group input,
.floating-label-form-group textarea {
	color: white;
	z-index: 1;
	position: relative;
	padding-right: 0;
	padding-left: 0;
	border: 0;
	border-radius: 0;
	font-size: 1em;
	background: 0 0;
	box-shadow: none !important;
	resize: none;
	width: 100%;
}

.floating-label-form-group label {
	display: block;
	z-index: 0;
	position: relative;
	top: 2em;
	margin: 0;
	font-size: 0.85em;
	line-height: 1.764705882em;
	vertical-align: middle;
	vertical-align: baseline;
	opacity: 0;
	transition: top 0.3s ease, opacity 0.3s ease;
}

.floating-label-form-group::not(:first-child) {
	padding-left: 14px;
	border-left: 1px solid #eee;
}

.floating-label-form-group-with-value label {
	top: 0;
	opacity: 1;
}

.floating-label-form-group-with-focus label {
	color: "#f00";
}

form .row:first-child .floating-label-form-group {
	border-top: 1px solid #eee;
}

.btn-success {
	color: white;
	border: 1px solid white;
	padding: 18px 27px;
	font-size: 19px;
	line-height: 1.33;
	border-radius: 0px;

	display: inline-block;
	margin-bottom: 0;
	font-weight: 400;
	text-align: center;
	vertical-align: middle;
	cursor: pointer;
	background-image: none;
	white-space: nowrap;
	padding: 10px 15px;
	font-size: 15px;
	line-height: 1.42857143;
	border-radius: 4px;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;

	background-color: #0d101d;
	transition: 0.5s;
	width: 100%;
}

.btn-success:hover {
	/* color: rgb(255, 255, 255); */
	color: #0d101d;
	background-color: white;
	border: 1px solid #f7941d;
}

#topbar {
	text-align: left;
	background-color: #111; /* Black background color */
	position: fixed; /* Make it stick/fixed */
	top: -100%; /* Hide the navbar 50 px outside of the top view */
	width: 100%; /* Full width */
	z-index: 100;
	transition-property: top;
	transition-duration: 0.75s;
	transition-timing-function: ease-in-out;
	box-shadow: 0 10px 20px 0 black;
	vertical-align: middle;
	display: flex; /* or inline-flex */
	justify-content: space-between;
	align-items: center;
	align-content: center;
}

.navlogo {
	min-height: 75px;
	height: 5vh;
	padding: 2%;
	max-width: 94%;
}
.navRight {
	float: right;
	width: auto;
	color: white;
	padding-right: 5%;
	max-width: 25%;
}
.navRight a {
	color: white;
}
.navRight .navFAIcon {
	min-height: 50px;
	height: 5vh;
	width: auto;
}
footer {
	color: white;
	padding: 20px;
}
footer > div {
	display: flex;
	justify-content: space-evenly;
}

.tab-content {
	padding-top: 1em;
	padding-bottom: 5em;
}

.nav-tabs {
	padding-bottom: 1px;
	border-bottom: 1px solid white;
}

.nav-tabs .nav-link {
	border: 1px solid transparent;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.nav-item {
	background-color: #111;
	color: white;
	width: 25%;
	min-width: 200px;
	margin-top: 1px;
}

.nav-item.active {
	background-color: white;
	color: #111;
	transition-property: background-color color;
	transition-duration: 1s;
}

.nav-tabs .nav-item.active:hover {
	color: #111;
	border-color: white;
}
.nav-tabs .nav-item:hover {
	color: white;
	border-color: #111;
}

.tab-content {
	color: white;
}
.tab-pane .imageRow {
	width: 100%;
	min-width: 25%;
	max-width: 100%;
	margin: auto;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tab-pane img {
	object-fit: cover;
	width: 100%;
	margin-top: 10px;
	border-radius: 5px;
	overflow: hidden;
	box-shadow: 5px 5px 20px 5px black;
}
.tab-pane .imageRow img {
	/* margin: auto;
	display: block;
	width: 50%;
	height: auto;
	padding: 10px; */
	object-fit: cover;
	width: 50%;
	/* min-width: 200px; */
	/* min-width: 150px; */
	margin: 10px;
}

.big-link {
	color: white;
	font-size: 2rem;
	font-weight: bold;
}

